import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import SEO from "../../components/SEO"
import Layout from "../../components/Layout"
import Header from "../../components/Header"
import Card from "../../components/Card"
import { smallContainer } from "../../components/container"
import WhatToDoNav from "../../components/WhatToDoNav"
import {
  ExternalLinkList,
  ExternalLink,
} from "../../components/ExternalLinkList"
import GasStationImage from "../../components/GasStationImage"

// Hero Section
const StyledCard = styled(Card)`
  margin: 50px auto;
`

const CardContent = styled.div`
  position: relative;
  z-index: 100;
`

const heroHeadline = css`
  font-size: 36px;
  position: relative;
  top: -25px;
  margin-bottom: 0;
`

const gasStationImage = css`
  position: absolute;
  z-index: 11;
  bottom: 150px;
  bottom: 20vh;
  left: 0;
  width: 360px;
  mix-blend-mode: multiply;

  @media (min-width: 500px) {
    width: 480px;
  }

  @media (min-width: 720px) {
    width: 650px;
  }

  @media (min-width: 860px) {
    width: 760px;
    bottom: 15%;
  }
`

const ArtAndArchitecture = () => (
  <Layout>
    <SEO title="Art + Architecture" />
    <Header />
    <div css={smallContainer}>
      <StyledCard>
        <CardContent>
          <h1 css={heroHeadline}>Art + Architecture</h1>
          <ExternalLinkList>
            <ExternalLink
              label="Palm Springs Art Museum + Architecture and Design"
              href="https://www.psmuseum.org/visit"
            />
            <ExternalLink
              label="Frey House II"
              href="https://www.psmuseum.org/visit/frey-house"
            />
            <ExternalLink
              label="Palm Springs Art Museum Architecture and Design Center"
              href="https://www.psmuseum.org/visit/psam-a-and-d-center"
            />
            <ExternalLink
              label="Cabots Pueblo Museum"
              href="https://www.cabotsmuseum.org/"
            />
            <ExternalLink
              label="Childrens Discovery Museum"
              href="https://cdmod.org/"
            />
            <ExternalLink
              label="Palm Springs Air Museum"
              href="https://palmspringsairmuseum.org/"
            />
          </ExternalLinkList>
          <p>
            There are also famous homes in Palm Springs like that of Liberace,
            Kaufmann House, Sunnylands and Frank Sinatra&rsquo;s grave-- in case
            you want to explore legends and history
          </p>
          <WhatToDoNav
            css={css`
              margin-top: 80px;
            `}
          />
        </CardContent>
        <div css={gasStationImage}>
          <GasStationImage />
        </div>
      </StyledCard>
    </div>
  </Layout>
)

export default ArtAndArchitecture
